<template>
  <li class="report-item mb-4">
    <div class="d-flex justify-between tagreport-block" v-if="entity?.fields?.relation?.value">
      <div class="d-flex flex-wrap align-center typography-7">
        <Icon :name="socialNetworkIcon(connectionItem?.parent)" v-if="socialNetworkIcon(connectionItem?.parent)" class="mr-10" />
        <img :src="avatar(connectionItem?.parent)" alt="" class="sidebar-avatar sidebar-avatar--big" @load="avatarLoadHandler('source')" @error="avatarErrorHandler('source')" :class="loadSourceAvatar ? 'd-block' : 'd-none'">
        <img src="@/assets/icons/avatar.png" alt="" class="sidebar-avatar sidebar-avatar--big" :class="loadSourceAvatar ? 'd-none' : 'd-block'">
        <Icon name="conn-element" class="mx-4" />
        <img :src="avatar(connectionItem)" alt="" class="sidebar-avatar sidebar-avatar--big mr-12" @load="avatarLoadHandler('')" @error="avatarErrorHandler('')" :class="loadDestinationAvatar ? 'd-block' : 'd-none'">
        <img src="@/assets/icons/avatar.png" alt="" class="sidebar-avatar sidebar-avatar--big mr-12" :class="loadDestinationAvatar ? 'd-none' : 'd-block'">
        <span class="color-2 mr-10">{{entity.value}}</span>
      </div>
      <div class="d-flex" v-if="tag">
        <button class="report-open-area typography-7 link mr-24" @click="openAreaInList(entity, tag.oid)">
          {{entity.showTextarea ? 'Hide' : 'View settings'}}
        </button>
        <input type="checkbox" name="" class="sidebar-checkbox select-entity" :checked="entity.checked">
        <label class="sidebar-checkbox__label" @click="checkEntity"></label>
      </div>
    </div>
    <div class="tagreport-block tagreport-block--item" :class="showNote ? 'tagreport-block--open' : ''" v-else>
      <div class="d-flex flex-wrap align-center typography-7">
        <Icon :name="socialNetworkIcon(entity)" v-if="socialNetworkIcon(entity)" class="mr-10" />
        <img :src="avatar(entity)" alt="" class="sidebar-avatar sidebar-avatar--big mr-8" @load="avatarLoadHandler('source')" @error="avatarErrorHandler('source')" :class="loadSourceAvatar ? 'd-block' : 'd-none'">
        <img src="@/assets/icons/avatar.png" alt="" class="sidebar-avatar sidebar-avatar--big mr-8" :class="loadSourceAvatar ? 'd-none' : 'd-block'">
        <!-- <div class="d-flex align-center mr-8">
          <Icon :name="checkUserDetails ? 'brick-icon-done' : 'brick-icon'" />
          <a class="tagreport-block__load link" @click="openProfile" v-if="checkUserDetails">profile details</a>
          <a class="tagreport-block__load" @click="openProfile" v-else>profile details</a>
        </div>
        <div class="d-flex align-center mr-12">
          <Icon :name="showScoreFilterBlock ? 'brick-icon-done' : 'brick-icon'" />
          <a class="tagreport-block__load link" @click="openConnections(showScoreFilterBlock)" v-if="showScoreFilterBlock">social graph</a>
          <span class="tagreport-block__load" v-else>social graph</span>
        </div> -->
        <span class="color-2 mr-10">{{name(entity)}}</span>
        <span class="color-theme" v-if="location(entity)">({{location(entity)}})</span>
      </div>
      <div class="d-flex" v-if="tag">
        <button class="report-open-area typography-7 link mr-24" @click="openAreaInList(entity, tag.oid)">
          {{entity.showTextarea ? 'Hide' : 'View settings'}}
        </button>
        <input type="checkbox" name="" class="sidebar-checkbox select-entity__else" :checked="entity.checked">
        <label class="sidebar-checkbox__label" @click="checkEntity"></label>
      </div>
    </div>
    <div class="note-block--shadow" v-if="entity.showTextarea && tag">
      <label for="" class="mb-12 color-miniheader typography-6 fw-600">Note</label>
      <textarea
        name=""
        id=""
        cols="30"
        rows="4"
        class="note-area w-100"
        placeholder="Place for your note"
        v-model="itemNote"
        @blur="saveNote(entity)"
      ></textarea>
      <div class="pt-24" v-if="connectionItem && Object.keys(connectionItem.posts).length">
        <div class="color-miniheader typography-6 fw-600 mb-12">Posts</div>
        <div class="d-flex justify-between">
          <ul class="score-checkboxes">
            <li class="radio-button">
              <input type="radio" name="posts" class="radio" id="p-10" value="10" :checked="entity.countPostsToShow === 10">
              <label class="" for="p-10" @click="countToShowPosts(10)"><span>10</span></label>
            </li>
            <li class="radio-button">
              <input type="radio" name="posts" class="radio" id="p-50" value="50" :checked="entity.countPostsToShow === 50">
              <label class="" for="p-50" @click="countToShowPosts(50)"><span>50</span></label>
            </li>
            <li class="radio-button">
              <input type="radio" name="posts" class="radio" id="p-100" value="100" :checked="entity.countPostsToShow === 100">
              <label class="" for="p-100" @click="countToShowPosts(100)"><span>100</span></label>
            </li>
            <li class="radio-button">
              <input type="radio" name="posts" class="radio" id="p-all" value="all" :checked="entity.countPostsToShow === 'all' || !entity.countPostsToShow">
              <label class="" for="p-all" @click="countToShowPosts('all')"><span>All</span></label>
            </li>
          </ul>
        </div>
      </div>
      <div class="pt-24" v-if="showScoreFilterBlock && tag">
        <div class="color-miniheader typography-6 fw-600 mb-12">Connections</div>
        <div class="d-flex justify-between">
          <ul class="score-checkboxes">
            <li>
              <input type="checkbox" name="" class="sidebar-checkbox" ref="s-250+" :checked="entity.scoreToShow?.['s-250+']">
              <label class="sidebar-checkbox__label mr-12" @click="checkConnections('s-250+')"><span>Score 250+</span></label>
            </li>
            <li>
              <input type="checkbox" name="" class="sidebar-checkbox" ref="s-150+" :checked="entity.scoreToShow?.['s-150+']">
              <label class="sidebar-checkbox__label mr-12" @click="checkConnections('s-150+')"><span>Score 150-249</span></label>
            </li>
            <li>
              <input type="checkbox" name="" class="sidebar-checkbox" ref="s-50+" :checked="entity.scoreToShow?.['s-50+']">
              <label class="sidebar-checkbox__label mr-12" @click="checkConnections('s-50+')"><span>Score 50-149</span></label>
            </li>
            <li>
              <input type="checkbox" name="" class="sidebar-checkbox" ref="s-1+" :checked="entity.scoreToShow?.['s-1+']">
              <label class="sidebar-checkbox__label mr-12" @click="checkConnections('s-1+')"><span>Score 1-49</span></label>
            </li>
          </ul>
          <div class="score-desc">
            <div class="fw-600">Connection score</div>
            <p><span class="fw-600">Friend (+50 points) –</span> the profile is in the subjects’s friend list</p>
            <p><span class="fw-600">Tagged with (+30 points) –</span> the person was tagged in a photo with the subject</p>
            <p><span class="fw-600">Liked post/photo (+3 points) –</span> the person liked the user's post or photo</p>
            <p><span class="fw-600">Commented post/photo (+3 points) –</span> the person commented on the  subject's post or photo</p>
          </div>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import Icon from '../app/Icon.vue';
import { getEntityName, getEntityAvatar, getLocation } from '../../utils/helpers';
import { mapGetters } from 'vuex';

export default {
  name: 'getReportItems',
  components: {
    Icon
  },
  data () {
    return {
      itemNote: '',
      showNote: false,
      sortedConnByScore: {
        's-1+': true,
        's-50+': true,
        's-150+': true,
        's-250+': true
      },
      loadSourceAvatar: false,
      loadDestinationAvatar: false
    };
  },
  props: ['entity', 'tag'],
  computed: {
    ...mapGetters(['results', 'sortedConnections', 'sortedRelationSearches']),
    screenUrl () {
      const foundScreenshot = this.$store.state.report.mapScreens.flat().filter(item => item.tag_id === this.tag.oid);
      return foundScreenshot || '';
    },
    connectionItem () {
      return this.sortedConnections.find(conn => {
        return this.entity.oid === conn.parent_id || this.entity.oid === conn.edge.oid;
      });
    },
    showScoreFilterBlock () {
      return this.sortedRelationSearches.find(relation => {
        return relation.targets.find(target => {
          return this.entity.oid === target;
        });
      });
    },
    checkUserDetails () {
      if (this.entity.fields?.['user-details']) {
        return true;
      } else {
        return false;
      }
    }
  },
  watch: {
    'entity.checked' (newValue) {
      this.$emit('change', newValue);
    }
  },
  async created () {
    this.itemNote = this.entity.note || '';

    if (this.connectionItem) {
      this.uploadPosts();
    }

    if (this.entity.scoreToShow) {
      this.sortedConnByScore = this.entity.scoreToShow;
    } else {
      this.$store.commit('setScoreForPreview', { resultId: this.entity.oid, score: this.sortedConnByScore });
    }
  },
  methods: {
    name (profile) {
      return getEntityName(profile);
    },
    avatar (profile) {
      return getEntityAvatar(profile);
    },
    location (profile) {
      return getLocation(profile);
    },
    socialNetworkIcon (profile) {
      try {
        for (const socialNet of ['instagram', 'Twitter', 'facebook', 'linkedin']) {
          if (profile.typeid.includes(socialNet)) {
            return 'person-card-' + socialNet.toLowerCase();
          }
        }
      } catch (e) {
        return '';
      }
    },
    openAreaInList (entity, tagOid) {
      if (entity.showTextarea === undefined) {
        entity.showTextarea = true;
        this.showNote = true;
      } else if (entity.showTextarea !== undefined) {
        entity.showTextarea = !entity.showTextarea;
        this.showNote = !this.showNote;
      }
      this.$nextTick(() => {
        const elem = document.querySelector('#sublist-' + tagOid);
        elem.style.maxHeight = elem.scrollHeight + 'px';
      });
    },
    checkEntity () {
      this.$store.commit('checkEdge', this.entity.oid);
      this.$store.commit('checkResultsProfile', this.entity.oid);
      this.$store.commit('checkConnectionEntity', this.entity.oid);
    },
    async saveNote (entity) {
      try {
        const obj = {
          entity: entity,
          caseId: this.$route.params.id,
          note: this.itemNote
        };
        await this.$store.dispatch('saveItemNote', obj);
      } catch (error) {
        console.error(error);
      }
    },
    async uploadPosts () {
      const payload = {
        method: 'POST',
        url: '/api/user/searches/nodes',
        body: {
          search_id: this.connectionItem.rid,
          parent_id: this.connectionItem.parent_id,
          levels: [4],
          limit: 1000,
          offset: 0
        }
      };
      while (true) {
        const response = await this.$store.dispatch('ajaxWithTokenRefresh', payload);
        if (response.status === 200) {
          const posts = response.data;
          await this.$store.dispatch('convertEntityFieldsToMap', posts);
          const postsMap = {};
          posts.forEach(post => {
            post.id = post.value;
            postsMap[post.value] = post;
          });
          this.$store.commit('updateConnection', { conn: this.connectionItem.oid, key: 'posts', val: postsMap });
        }
        if (response.data.length < payload.body.limit) {
          break;
        }
        payload.body.offset += payload.body.limit;
      }
    },
    countToShowPosts (count) {
      this.$store.commit('countToShowPosts', { oid: this.entity.oid, count: count });
    },
    checkConnections (score) {
      const elem = this.$refs[score];
      if (elem.checked) {
        elem.checked = false;
        this.sortedConnByScore[score] = false;
      } else {
        elem.checked = true;
        this.sortedConnByScore[score] = true;
      }
      this.$store.commit('setScoreForPreview', { resultId: this.entity.oid, score: this.sortedConnByScore });
    },
    async openConnections (search) {
      this.$store.commit('activateRelationsSection', true);
      this.$router.push({ path: '/case/' + this.$route.params.id });
      await this.$store.dispatch('openConnections', search);
      this.$nextTick(async () => { await this.$store.dispatch('stopSearching', true); });
      document.getElementsByClassName('content')[0].scrollIntoView();
    },
    openProfile () {
      this.$router.push(`/case/${this.$route.params.id}`);
      this.$store.commit('openResults', false);
      this.$store.commit('openConnections', false);
      this.$store.commit('openMultiprofile', false);
      this.$store.commit('clearAllSavedInTagsEntities');
      this.$store.commit('addProfileToSavedInTagsPage', this.entity);
      this.$store.commit('showAllSavedInTags', false);
      this.$store.commit('openSavedInTagsPage', true);
      document.getElementsByClassName('content')[0].scrollIntoView();
    },
    avatarLoadHandler (type) {
      if (type === 'source') {
        this.loadSourceAvatar = true;
      } else {
        this.loadDestinationAvatar = true;
      }
    },
    avatarErrorHandler (type) {
      if (type === 'source') {
        this.loadSourceAvatar = false;
      } else {
        this.loadDestinationAvatar = false;
      }
    }
  }
};
</script>
