<template>
  <Layout>
    <template v-slot:sidebar>
      <SidebarCase />
    </template>
    <template v-slot:content>
      <ReportComponent />
    </template>
  </Layout>
</template>

<script>
import Layout from '@/components/UI/Layout';
import SidebarCase from '@/viewsNew/SidebarCase';
import ReportComponent from '@/components/report-new/ReportComponent';
import { mapMutations } from 'vuex';

export default {
  components: {
    Layout,
    SidebarCase,
    ReportComponent
  },
  created () {
    this.SET_HIDE_CHECKBOXES(true);
  },
  unmounted () {
    this.SET_HIDE_CHECKBOXES(false);
  },
  methods: {
    ...mapMutations(['SET_HIDE_CHECKBOXES'])
  }
};
</script>
